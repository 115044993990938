import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import {
    HttpRequest,
    HttpInterceptor,
    HttpHandler,
    HttpUserEvent,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';

import { LocalStorage } from 'ngx-store';
import { catchError, switchMap, finalize, filter, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

type HttpEvent =
    HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    @LocalStorage('accessToken') accessToken: string;

    isRefreshingToken = false;
    tokenSubject = new BehaviorSubject<boolean>(false);

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
    }

    addToken(req: HttpRequest<any>): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + this.accessToken }});
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent> {
        return next.handle(this.addToken(req))
        .pipe(
            catchError(
                error => {
                    if (error instanceof HttpErrorResponse && error.status === 401) {
                        return this.handle401Error(req, next, error);
                    } else {
                        return throwError(error);
                    }
                }
            )
        );
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent> {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(false);

            if (error.error.code === 'invalid_grant') {
                this.logoutUser(error);
            }

            return this.authService.refresh()
                .pipe(
                    switchMap(() => {
                        this.tokenSubject.next(true);
                        return next.handle(this.addToken(req));
                    }),
                    catchError(() =>
                        this.logoutUser(error)
                    ),
                    finalize(() =>
                        this.isRefreshingToken = false
                    )
                );
        } else {
            return this.tokenSubject
                .pipe(
                    filter(token => token !== false),
                    take(1),
                    switchMap(token =>
                        next.handle(this.addToken(req))
                    )
                );
        }
    }

    logoutUser(error): Observable<never> {
        return this.authService.signOut().pipe(
            tap(
                () => this.router.navigate(['/'])
            ),
            switchMap(() => throwError(error))
        );
    }
}
