import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { WebStorageModule } from 'ngx-store';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthService } from './services/auth.service';
import { FileOverlayComponent } from './file-overlay/file-overlay.component';
import { FileOverlayService } from './file-overlay/file-overlay.service';
import { MatProgressSpinnerModule, MatDialogModule } from '@angular/material';
import { FileOverlayToolbarComponent } from './file-overlay/file-overlay-toolbar/file-overlay-toolbar.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FileSizePipe } from './file-size.pipe';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    WebStorageModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDialogModule
  ],
  declarations: [
    FileOverlayComponent,
    FileOverlayToolbarComponent,
    FileSizePipe,
    ConfirmDialogComponent
  ],
  exports: [
    CommonModule,
    ConfirmDialogComponent
  ],
  entryComponents: [
    FileOverlayComponent,
    ConfirmDialogComponent
  ]
})
export class CoreModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        AuthService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        FileOverlayService
      ]
    };
  }
}
