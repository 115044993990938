import {
  Component,
  AfterViewInit,
  OnInit,
  ViewChildren,
  ViewChild,
  QueryList,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { ProfileService, Result } from '../profile.service';

import { Observable, Subject, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

export interface Constraint {
  part: string;
  dependants: {
    _id: string;
    profiles: string[];
  }[];
}

@Component({
  selector        : 'app-calculator',
  templateUrl     : './calculator.component.html',
  styleUrls       : ['./calculator.component.scss']
})
export class CalculatorComponent implements AfterViewInit, OnInit, OnChanges {

  @ViewChildren('parts') private parts: QueryList<HTMLSelectElement>;
  @ViewChild('f') private form: HTMLFormElement;

  selectedType: string;
  selectedSubType: string;
  selectedSeries: string;

  activeParts: string[];

  enable: boolean;
  error: boolean;

  types = ['Ανοιγόμενο', 'Συρόμενο'];

  Ugs = [0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2];

  subtypes = {
    ['Ανοιγόμενο']: [
      'Μονόφυλλο',
      'Δίφυλλο',
      'Τρίφυλλο',
      'Τετράφυλλο',
      'Μονόφυλλη Πόρτα',
      'Δίφυλλη Πόρτα',
      'Σταθερό',
    ],
    ['Συρόμενο']: [
      'Μονόφυλλο Χωνευτό',
      'Δίφυλλο Χωνευτό',
      'Δίφυλλο Επάλληλο',
      'Τρίφυλλο Επάλληλο',
      'Τετράφυλλο Επάλληλο',
    ]
  };

  series: Observable<[ string[], string[] ]>;
  mySeries;
  thermal: Map<string, boolean>;
  optionsSubject = new Subject<{ [key: string]: string[] }>();
  options: Observable<{ [key: string]: string[] }> = this.optionsSubject.asObservable();
  constrains: Constraint[];
  initialOptions: { [key: string]: string[] };
  Uw: Observable<Result>;

  seletedBlindsType = '';

  image: string;
  eee = true;
  Yg: number = 0.11;

  get isSelectedSeriesThermo(): boolean {
    return this.mySeries &&
            this.mySeries.length > 0 &&
            this.mySeries[0] &&
            this.mySeries[0].length > 0 &&
            this.mySeries[0].includes(this.selectedSeries);
  }

  get isSelectedGlassThermo(): boolean {
    return this.eee === true;
  }

  constructor(
    private profileService: ProfileService,
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isSelectedGlassThermo && this.isSelectedSeriesThermo) {
      this.Yg = 0.11;
    } else {
      this.Yg = 0.049;
    }
  }

  updateOptions() {
    const newOptions = this.constrains.reduce(
      (all, c) =>
        c.dependants
        .filter(dep => Array.isArray(all[dep._id]))
//        .map(x => { console.log(x); return x; })
        .reduce((tmp, dep) => ({
          ...tmp,
          [dep._id]: tmp[dep._id].filter(x => dep.profiles.includes(x))
        }), all),
      this.initialOptions
    );
    this.optionsSubject.next(newOptions);
  }

  partChanged(part: string, i: number) {
    if (!this.thermal.get(this.selectedSeries)) {
      return;
    }
    const profileId = this.parts.toArray()[i].value;
    this.constrains = this.constrains.filter(x => x.part !== part);
    if (!profileId) {
      this.updateOptions();
      return;
    }
    this.profileService
    .getRelated(profileId)
    .pipe(
      tap(dependants => this.constrains.push({ part, dependants })),
      tap(_ => this.updateOptions())
    )
    .subscribe(_ => console.log(this.constrains));
  }

  reset(part: string, i: number) {
    this.parts.toArray()[i].value = null;
  }

  decimal(x: { value: string }, max: number = 2) {
    if (+x.value > 10) {
      x.value = x.value[0] + '.' + x.value.substr(1, max);
    } else if (x.value.startsWith('10')) {
      x.value = '1.0' + x.value.substr(3, max - 1);
    } else if (x.value.startsWith('0') && !x.value.includes('.') && x.value.length > 1) {
      x.value = '0.' + x.value.substr(1, max);
    } else if (x.value.length > 4) {
      x.value = x.value.substr(0, 2 + max);
    }
  }

  allowShutter(subtype): boolean {
    return !!subtype && !subtype.match(/(Πόρτα|Σταθερό|Επάλληλο)/g);
  }

  allowRoll(subtype): boolean {
    return !!subtype && !subtype.match(/(Πόρτα)/g);
  }

  typeChanged() {
    const type = this.selectedType === 'Ανοιγόμενο' ? 10 : 210;
    console.log('in');
    this.series = this.profileService
    .getByTypeCode(type)
    .pipe(
      tap(x =>
        this.thermal = x
          .reduce(
            (m, series) =>
              m.set(series._id, series.thermal),
              new Map<string, boolean>()
            )
      ),
      map(x => x
        .reduce(
          (all, s) => {
            const index = s.thermal ? 0 : 1;
            all[index].push(s._id);
            return all;
          },
          [ [], [] ]
        )
      ),
      tap(series => this.mySeries = [ ...series ])
    ) as any;
    this.constrains = [];
    this.selectedSubType = undefined;
    this.selectedSeries = undefined;
    this.activeParts = [];
    this.enable = false;
    this.image = undefined;
  }

  selectImage() {
    if (!this.selectedType || !this.selectedSubType) {
      return;
    }
    const type = this.profileService.translate(this.selectedType).default;
    const subtype = this.profileService.translate(this.selectedType, this.selectedSubType);
    switch (this.seletedBlindsType) {
      case 'roll':
        this.image = '../assets/images/profiles/' + type + '/' + subtype[0] + 'X' + subtype[1] + '/roll.png';
        break;
      case 'shutter':
        this.image = '../assets/images/profiles/' + type + '/' + subtype[0] + 'X' + subtype[1] + '/shutter.png';
        break;
      default:
        this.image = '../assets/images/profiles/' + type + '/' + subtype[0] + 'X' + subtype[1] + '/base.png';
        break;
    }
  }

  subTypeChanged() {
    this.selectedSeries = undefined;
    this.activeParts = [];
    this.enable = false;
    switch (this.seletedBlindsType) {
      case 'roll':
        if (!this.allowRoll(this.selectedSubType)) {
          this.seletedBlindsType = '';
        }
        break;
      case 'shutter':
        if (!this.allowShutter(this.selectedSubType)) {
          this.seletedBlindsType = '';
        }
        break;
      default:
        break;
    }
    this.selectImage();
    this.constrains = [];
  }

  seriesSelected(v: any) {
    this.activeParts = options[this.selectedType][this.selectedSubType];
    this.profileService
    .getOptionsOfSeries(this.selectedSeries, this.activeParts)
    .pipe(
      map(x => {
        this.initialOptions = x;
        this.optionsSubject.next(x);
      })
    )
    .subscribe();
    this.constrains = [];
    this.enable = true;
    if (v.parts) {
      Object.keys(v.parts).map(part => v.parts[part] = '');
    }
  }

  onSubmit(x) {
    this.Uw = this.profileService.calculate(this.selectedType, this.selectedSubType, {
      profiles: Object.values(x.parts).filter(v => v !== '') as any,
      width: x.width,
      height: x.height,
      blinds: x.blinds.type !== '' ? x.blinds : undefined,
      Ug: x.Ug,
      Yg: this.isSelectedSeriesThermo && this.eee ? this.Yg : undefined,
      bottomUg: x.bottomUg,
      tafHeight: x.tafHeight,
      Up: x.Up,
      bottomUp: x.bottomUp,
    })
    .pipe(
      map(data => ({
        ...data,
        frameType: this.selectedType,
        frameSubtype: this.selectedSubType,
        image: this.image,
        width: x.width,
        height: x.height,
        series: this.selectedSeries,
        parts: Object.keys(x.parts)
          .filter(name => x.parts[name] !== '')
          .map(name => ({ name, profile: x.parts[name].replace('ALC-', '') }))
      })),
      tap(_ => this.error = false),
      catchError(e => { this.error = true; return throwError(e); })
    );
  }

  disabled(x: string, v: any) {
    return v.parts
    && (
      (x === 'ΚΑΤΩΚΑΣΙ' && v.parts['ΤΑΜΠΛΑΣ'] !== undefined && v.parts['ΤΑΜΠΛΑΣ'] !== '')
      || (x === 'ΤΑΜΠΛΑΣ' && v.parts['ΚΑΤΩΚΑΣΙ'] !== undefined && v.parts['ΚΑΤΩΚΑΣΙ'] !== '')
    );
  }

  round(x: number): number {
    return Math.round(x * 10) / 10;
  }
}

/*
this.profileService.getSelected(Object.values(x))
.subscribe(data => {
  switch(this.selectedType + ' ' + this.selectedSubType) {
    case 'Ανοιγόμενο Μονόφυλλο':
      const casing = data['ΚΑΣΑ'];
      const window = data['ΦΥΛΛΟ ΑΝΟΙΓΟΜΕΝΟΥ']
      const offset = casing.height.internal + window.height.external + window.step;
      const Ag = (x.width - 2 * offset) * (x.height - 2 * offset);
      const Af = x.width * x.height - Ag;
      console.log('Af: ', Af);
    break;
  }
});
*/

const options = {
  ['Ανοιγόμενο']: {
    ['Μονόφυλλο']: ['ΚΑΣΑ', 'ΦΥΛΛΟ ΑΝΟΙΓΟΜΕΝΟΥ'],
    ['Δίφυλλο']: ['ΚΑΣΑ', 'ΦΥΛΛΟ ΑΝΟΙΓΟΜΕΝΟΥ', 'ΜΠΙΝΙ'],
    ['Τρίφυλλο']: ['ΚΑΣΑ', 'ΦΥΛΛΟ ΑΝΟΙΓΟΜΕΝΟΥ', 'ΜΠΙΝΙ'],
    ['Τετράφυλλο']: ['ΚΑΣΑ', 'ΦΥΛΛΟ ΑΝΟΙΓΟΜΕΝΟΥ', 'ΜΠΙΝΙ'],
    ['Μονόφυλλη Πόρτα']: ['ΚΑΣΑ', 'ΦΥΛΛΟ ΑΝΟΙΓΟΜΕΝΟΥ', 'ΚΑΤΩΚΑΣΙ', 'ΤΑΦ', 'ΤΑΜΠΛΑΣ'],
    ['Δίφυλλη Πόρτα']: ['ΚΑΣΑ', 'ΦΥΛΛΟ ΑΝΟΙΓΟΜΕΝΟΥ', 'ΜΠΙΝΙ', 'ΚΑΤΩΚΑΣΙ', 'ΤΑΦ', 'ΤΑΜΠΛΑΣ'],
    ['Σταθερό']: ['ΚΑΣΑ'],
  },
  ['Συρόμενο']: {
    ['Μονόφυλλο Χωνευτό']: ['ΟΔΗΓΟΣ', 'ΦΥΛΛΟ ΣΥΡΟΜΕΝΟΥ', 'ΑΡΜΟΚΑΛΥΠΤΡΟ'],
    ['Δίφυλλο Χωνευτό']: ['ΟΔΗΓΟΣ', 'ΦΥΛΛΟ ΣΥΡΟΜΕΝΟΥ', 'ΜΠΙΝΙ ΣΥΡΟΜΕΝΟΥ', 'ΑΡΜΟΚΑΛΥΠΤΡΟ'],
    ['Δίφυλλο Επάλληλο']: ['ΟΔΗΓΟΣ', 'ΦΥΛΛΟ ΣΥΡΟΜΕΝΟΥ', 'ΓΑΤΖΟΣ'],
    ['Τρίφυλλο Επάλληλο']: ['ΟΔΗΓΟΣ', 'ΦΥΛΛΟ ΣΥΡΟΜΕΝΟΥ', 'ΓΑΤΖΟΣ'],
    ['Τετράφυλλο Επάλληλο']: ['ΟΔΗΓΟΣ', 'ΦΥΛΛΟ ΣΥΡΟΜΕΝΟΥ', 'ΓΑΤΖΟΣ'],
  }
};

