import { Injectable } from '@angular/core';
import {
  LocalStorageService,
  SessionStorageService
} from 'ngx-store';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface SignInSchema {
  email: string;
  password: string;
}

export type UserRole = 'admin' | 'client';

interface AccessTokenResponce {
  accessToken: string;
  role: UserRole;
}

interface SignInResponce extends AccessTokenResponce {
  refreshToken: string;
}

@Injectable()
export class AuthService {

  private readonly endpoint = '/api/auth';
  private readonly REFRESH_TOKEN = 'refreshToken';
  private readonly ACCESS_TOKEN = 'accessToken';

  constructor(
    private http: HttpClient,
    private local: LocalStorageService,
    private session: LocalStorageService
  ) {
  }

  refreshTokenExists(): boolean {
    return !!this.local.get(this.REFRESH_TOKEN);
  }

  signIn(credentials: SignInSchema): Observable<UserRole> {
    return this.http
    .post<SignInResponce>(
      `${this.endpoint}/signin`,
      credentials
    ).pipe(
      tap(response => this.local.set(this.REFRESH_TOKEN, response.refreshToken)),
      tap(response => this.session.set(this.ACCESS_TOKEN, response.accessToken)),
      map(responce => responce.role),
    );
  }

  refresh(): Observable<UserRole> {
    const refreshToken = this.local.get(this.REFRESH_TOKEN);

    if (!refreshToken) {
      return throwError(new Error('No token'));
    }

    return this.http
    .post<AccessTokenResponce>(
      `${this.endpoint}/refresh`,
      {
        refreshToken
      }
    ).pipe(
      tap(response => this.session.set(this.ACCESS_TOKEN, response.accessToken)),
      map(responce => responce.role)
    );
  }

  signOut(): Observable<{}> {
    const refreshToken = this.session.get(this.REFRESH_TOKEN);

    return this.http.post( `${this.endpoint}/signout`, { refreshToken })
    .pipe(
      tap(
        () => {
          this.local.remove(this.REFRESH_TOKEN);
          this.session.remove(this.ACCESS_TOKEN);
        }
      )
    );
  }
}
