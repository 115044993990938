import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { map } from 'rxjs/operators';

export interface Options {
  type: string;
  profiles: string[];
}

export interface Frame {
  profiles: string[];
  width: number;
  height: number;
  Ug: number;
  Yg: number;
  Urb?: number;
  windows?: number;
  boxHeight?: number;
  blinds?: {
    type: 'roll' | 'shutter',
    Urb: number,
    boxHeight: number,
  };
  tafHeight?: number;
  bottomUg?: number;
  bottomUp?: number;
  Up?: number;
}

export interface Result {
  Uw: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  endpoint = environment.apiUrl + '/profiles';

  codeMap = {
    ['ΚΑΣΑ']: 10,
    ['ΦΥΛΛΟ ΑΝΟΙΓΟΜΕΝΟΥ']: 20,
    ['ΜΠΙΝΙ']: 50,
    ['ΚΑΤΩΚΑΣΙ']: 110,
    ['ΤΑΦ']: 60,
    ['ΤΑΜΠΛΑΣ']: 80,
    ['ΟΔΗΓΟΣ']: 210,
    ['ΦΥΛΛΟ ΣΥΡΟΜΕΝΟΥ']: 230,
    ['ΜΠΙΝΙ ΣΥΡΟΜΕΝΟΥ']: 260,
    ['ΓΑΤΖΟΣ']: 250,
    ['ΑΡΜΟΚΑΛΥΠΤΡΟ']: 200,
  };

  codeReverseMap = {
    ['10']: 'ΚΑΣΑ',
    ['20']: 'ΦΥΛΛΟ ΑΝΟΙΓΟΜΕΝΟΥ',
    ['50']: 'ΜΠΙΝΙ',
    ['110']: 'ΚΑΤΩΚΑΣΙ',
    ['60']: 'ΤΑΦ',
    ['80']: 'ΤΑΜΠΛΑΣ',
    ['210']: 'ΟΔΗΓΟΣ',
    ['230']: 'ΦΥΛΛΟ ΣΥΡΟΜΕΝΟΥ',
    ['260']: 'ΜΠΙΝΙ ΣΥΡΟΜΕΝΟΥ',
    ['250']: 'ΓΑΤΖΟΣ',
    ['200']: 'ΑΡΜΟΚΑΛΥΠΤΡΟ',
  };

  types = {
    ['Ανοιγόμενο']: {
      default: 'Openable',
      ['Μονόφυλλο']: ['Normal', 1],
      ['Δίφυλλο']: ['Normal', 2],
      ['Τρίφυλλο']: ['Normal', 3],
      ['Τετράφυλλο']: ['Normal', 4],
      ['Μονόφυλλη Πόρτα']: ['Door', 1],
      ['Δίφυλλη Πόρτα']: ['Door', 2],
      ['Σταθερό']: ['Static', 1],
    },
    ['Συρόμενο']: {
      default: 'Dragable',
      ['Μονόφυλλο Χωνευτό']: ['Nested', 1],
      ['Δίφυλλο Χωνευτό']: ['Nested', 2],
      ['Δίφυλλο Επάλληλο']: ['Superimposed', 2],
      ['Τρίφυλλο Επάλληλο']: ['Superimposed', 3],
      ['Τετράφυλλο Επάλληλο']: ['Superimposed', 4],
    }
  };

  constructor(
    private http: HttpClient,
  ) {
  }

  translate(type: string, subtype?: string) {
    return (subtype) ? this.types[type][subtype] : this.types[type];
  }

  getRelated(profileId: string) {
    const params = new HttpParams().append('profile', profileId);
    return this.http
    .get<{ _id: string, profiles: string[] }[]>(this.endpoint + '/related', { params })
    .pipe(
      map(constraint => constraint.map(x => ({ ...x, _id: this.codeReverseMap[x._id] })))
    );
  }

  getByTypeCode(type: number) {
    return this.http.get<{ _id: string, thermal: boolean }[]>(this.endpoint + '/type/' + type);
  }

  getOptionsOfSeries(series: string, types: string[]) {
    const params = types.map(x => this.codeMap[x]).reduce((p: HttpParams, x) => p.append('types', x) , new HttpParams());
    return this.http
    .get<Options[]>(this.endpoint + '/series/' + series, { params })
    .pipe(
      map(x => x.reduce((obj, c) => ({ ...obj, [this.codeReverseMap[c.type]]: c.profiles }), {}))
    );
  }

  getSelected(profiles: string[]) {
    const params = profiles.reduce((p: HttpParams, x) => p.append('profiles', x) , new HttpParams());
    return this.http
    .get<any>(this.endpoint + '/selected', { params })
    .pipe(
      map(data => data.reduce((obj, c) => ({ ...obj, [this.codeReverseMap[c.type]]: c }), {}))
    );
  }

  calculate(type: string, subtype: string, frame: Frame) {

    const eType = this.types[type];
    const eSubType = eType[subtype];

    let params = new HttpParams().
    append('width', frame.width.toString()).
    append('height', frame.height.toString()).
    append('windows', eSubType[1].toString());

    if (frame.bottomUg) {
      params = params.append('bottomUg', frame.bottomUg.toString());
    }

    if (frame.bottomUp) {
      params = params.append('bottomUp', frame.bottomUp.toString());
    }

    if (frame.tafHeight) {
      params = params.append('tafHeight', frame.tafHeight.toString());
    }

    if (frame.Ug) {
      params = params.append('Ug', frame.Ug.toString());
    }

    if (frame.Yg) {
      params = params.append('Yg', frame.Yg.toString());
    }

    if (frame.Up) {
      params = params.append('Up', frame.Up.toString());
    }

    if (frame.blinds) {
      params = params.append('blindsType', frame.blinds.type);
      if (frame.blinds.type === 'roll') {
        params = params
        .append('blindsUrb', frame.blinds.Urb.toString())
        .append('blindsBoxHeight', frame.blinds.boxHeight.toString());
      }
    }

    params = frame.profiles.reduce((p, x) => p.append('profiles', x), params);

    console.log('Params: ', params);

    return this.http.get<Result>(this.endpoint + '/' + eType.default + '/' + eSubType[0], { params });
  }
}
