import { Component, OnInit, Inject, Input } from '@angular/core';

export interface Result {
  image: string;
  width: number;
  height: number;
  series: string;
  type: string[];
  frameType: string;
  frameSubtype: string;
  parts: { name: string, profile: string };
  Uw?: number; //
  Af?: number;
  Ag?: number; //
  lg?: number; //
  Yg?: number; //
  Ug?: number; //
  Up?: number;
  Uf?: number;
  Aft?: number; //
  Uft?: number;
  Afr?: number; //
  Ufr?: number;
  Afc?: number; //
  Ufc?: number;
  Afs?: number; //
  Ufs?: number;
  Uwrb?: number; //
  Uwc?: number; //
  Rrb?: number; //
  Arb?: number; //
  Ugb?: number;
  Upb?: number;
  Ygb?: number;
  lgb?: number;
  multiplier?: number;
  numerator: number;
  outerArea?: number;
}

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
  providers: [
    { provide: 'Window', useValue: window }
  ]
})
export class ResultsComponent implements OnInit {

  @Input() data: Result;

  sectionIds = ['f', 'fc', 'ft', 'fr', 'fs'];

  private sections = [
    'Κάσα', // 0
    'Κάσα-Φύλλο', // 1
    'Φυλλο-Μπινί-Φύλλο', // 2
    'Φύλλο-Ταφ', // -- 3
    'Φύλλο-Ταφ-Φύλλο', // 4
    'Φύλλο-Κατωκάσι', // 5
    'Ταμπλάς', // 6
    'Οδηγός-Φύλλο', // 7
    'Φύλλο-Γάτζος', // 8
    'Φύλλο-Μπινί Συρομένου', // 9
    'Φύλλο-Αρμοκάλυπτρο', // 10
  ];

  sectionNames = {
    Af: this.sections[0] || this.sections[1] || this.sections[7],
    Aft: this.sections[4],
    Afr: this.sections[2] || this.sections[8] || this.sections[9],
    Afc: this.sections[10],
    Afs: this.sections[5] || this.sections[6],
  };

  get section(): { [key: string]: string } {
    if (this.data.type.includes('Openable')) {
        return {
          f: this.data.type.includes('Static') ? this.sections[0] : this.sections[1],
          ft: this.sections[4],
          fr: this.sections[2],
          fs: this.data.type.includes('sill') ? this.sections[5] : this.sections[6],
        };
    } else {
        return {
          f: this.sections[7],
          fr: this.data.type.includes('Superimposed') ? this.sections[8] : this.sections[9],
          fc: this.sections[10],
        };
    }
  }

  constructor(
    @Inject('Window') private window: Window,
  ) { }

  ngOnInit() {
  }

  get totalArea(): number {
    return this.roundDetail(this.data.width * this.data.height);
  }

  get totalAluminumArea(): number {
    const sum = [
      this.data.Af,
      this.data.Aft,
      this.data.Afc,
      this.data.Afr * (this.data.multiplier || 1),
      this.data.Afs,
    ]
    .filter(x => !!x)
    .reduce((s, x) => s + x, 0);

    return this.roundDetail(sum);
  }

  roundDetail(x: number) {
    return Math.round(x * 10000) / 10000;
  }

  print() {
    window.print();
  }

  round(x: number): number {
    return Math.round(x * 10) / 10;
  }
}
